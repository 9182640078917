<template>
    <v-container style="max-width:500px;text-align:center;">
        <h1 class="mt-4">{{erroTitulo}}</h1>
        <h3 class="mt-5 font-weight-light">{{erroDescription}}</h3>
        <v-btn color="blue" height="50" class="mt-5 white--text" width="250" v-if="origin!=null" :href="origin">TENTAR NOVAMENTE</v-btn>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            erroTitulo:"",
            erroDescription:"",
            origin: null,
            error:[
                { error:"404", titulo:"404 - Página não encontrada", descritivo: "Verifique a url acessada." },
                { error:"405", titulo:"405 - Empresa Inválida", descritivo: "Não foi encontrado nenhuma empresa com os dados informados." },
                { error:"406", titulo:"406 - Verifique sua conexão", descritivo: "Não foi possível se conectar aos serviços, verifique sua conexão." }
            ]
        }
    },
    methods:{
        verificaErro(){
            if(!!this.$route.query){
                let errorTemp = this.error.filter(item=>{return item.error == this.$route.query.type});
                if(errorTemp.length>0){
                    this.erroTitulo = errorTemp[0].titulo;
                    this.erroDescription = errorTemp[0].descritivo;
                }else{
                    this.origin = null;
                    this.erroTitulo = this.error[0].titulo;
                    this.erroDescription = this.error[0].descritivo;
                }
                if(!!this.$route.query.origin){
                    this.origin = this.$route.query.origin;
                }
            }else{
                this.origin = null;
                this.erroTitulo = this.error[0].titulo;
                this.erroDescription = this.error[0].descritivo;
            }
        }
    },
    created(){
        console.log(this.$route)
        this.verificaErro();
    }
}
</script>